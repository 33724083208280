<template>
  <Layout :tituloPagina="'Network | Sitios | '+(modo == 'nuevo' ? 'Nuevo' : 'Edición')">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          {{ modo == 'nuevo' ? 'Nuevo' : 'Edición de' }} sitio
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="modo == 'nuevo' ? guardar() : actualizar()"
                  v-if="!bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="cerrar()"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 mb-3">
            <label>Nombre</label>
            <input
              ref="nombre"
              class="form-control"
              placeholder="Nombre del sitio"
              name="nombre"
              v-model="sitio.nombre"
            />
            <br />
            <label>Dirección</label>
            <textarea
              rows="5"
              class="form-control"
              placeholder="Descripción del sitio"
              v-model="sitio.direccion"
            ></textarea>
          </div>
          <div class="col-md-6">
            <label>Posición</label>
            <GoogleMap
              ref="mapa"
              :api-key="apikeyGoogle"
              :center="posicionInicial"
              style="width: 100%; height: 371px;"
              :styles="modoTemplate == 'dark' ? styles_dark : styles_light"
              :zoom="mapaZoom"
            >
              <Marker
                ref="marker"
                :options="{ 
                  position: posicionInicial,
                  icon: markerIcon,
                  draggable: true
                }"
                @dragend="mostrarPosicion($event)"
              ></Marker>
            </GoogleMap>
            <br>
            <div class="input-group">
              <span class="input-group-text">
                <span class="d-none d-sm-block">
                  Latitud,Longitud
                </span>
                <span class="d-block d-sm-none">
                  Lat,Lng
                </span>
              </span>
              <input
                type="text"
                class="form-control text-right"
                v-model="lat_lng"
                :readonly="bloquear_lat_lng"
                @change="actualizarPosicion()"
              />
              <button class="btn btn-light" 
                @click="bloquear_lat_lng = !bloquear_lat_lng"
              >
                <i
                  class="mdi"
                  :class="{
                    'mdi-lock-outline': bloquear_lat_lng,
                    'mdi-lock-open-variant-outline': !bloquear_lat_lng
                  }"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <div class="btn-group">
        <button @click="atras()" class="btn btn-secondary">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>

        <button
          class="btn btn-success"
          @click="modo == 'nuevo' ? guardar() : actualizar()"
          :disabled="bandera_spinner"
        >
          <i
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import markerIcon from '@/assets/img/locationAP.png'
import { GoogleMap, Marker } from "vue3-google-map"
import SitioSrv from '@/services/SitioSrv.js'
import SistemaSrv from '@/services/SistemaSrv.js'
export default {
  name: 'EdicionSitio',
  components: {
    Layout,
    GoogleMap,
    Marker
  },
  data() {
    return {
      modo: 'nuevo',
      mapaIniciado: false,
      sitio: {
        nombre: '',
        direccion: '',
        latitud: 0,
        lingitud: 0
      },
      sitioInicial: {
        nombre: '',
        direccion: '',
        latitud: 0,
        lingitud: 0
      },

      markerIcon:markerIcon,
      mapaZoom: 12,
      posicionInicial: {
        lat: 0,
        lng: 0
      },
      lat_lng: 0,
      bloquear_lat_lng: true,

      styles_dark: [
        {
          featureType: "all",
          elementType: "all",
          stylers: [
            { invert_lightness: true },
            { saturation: 10 },
            { lightness: 30 },
            { gamma: 0.5 },
            { hue: "#435158" }
          ]
        },{
          featureType: "poi",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 },
            { lightness: 0 }
          ]
        },{
          featureType: "transit",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 },
            { lightness: 0 }
          ]
        },{
          featureType: "road",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 }
          ]
        }
      ],
      styles_light:[
        {
          featureType: "administrative",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 20 }
          ]
        },{
          featureType: "road",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 40 }
          ]
        },{
          featureType: "water",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -10 },
            { lightness: 30 }
          ]
        },{
          featureType: "landscape.man_made",
          elementType: "all",
          stylers: [
            { visibility: "simplified" },
            { saturation: -60 },
            { lightness: 10 }
          ]
        },{
          featureType: "landscape.natural",
          elementType: "all",
          stylers: [
            { visibility: "simplified" },
            { saturation: -60 },
            { lightness: 60 }
          ]
        },{
          featureType: "poi",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 60 }
          ]
        },{
          featureType: "transit",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 60 }
          ]
        }
      ],
      bandera_spinner: false
    }
  },

  computed: {
    apikeyGoogle() {
      return this.$store.state.todo.apiKeyGoogleMaps
    },
    modoTemplate: function() {
      return this.$store.state.layout.layoutMode
    }
  },

  created: function() {
    var self = this

    self.sitioInicial = Object.assign({}, self.sitio)

    if (this.$route.path.indexOf('nuevo') == -1) this.modo = 'edicion'

    // Cargas iniciales
    if (this.modo == 'nuevo') {
      SistemaSrv.posicionInicial().then(response => {
        let posicion = response.data

        self.sitio.latitud = posicion.latitud
        self.sitio.longitud = posicion.longitud

        self.posicionInicial = {
          lat: parseFloat(posicion.latitud),
          lng: parseFloat(posicion.longitud)
        }

        self.lat_lng = parseFloat(posicion.latitud)+','+parseFloat(posicion.longitud)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : ''
        console.log(error)
      })
    } else {
      SitioSrv.sitioJSON(self.$route.params.id).then(response => {
        self.sitio = response.data

        self.posicionInicial = {
          lat: parseFloat(self.sitio.latitud),
          lng: parseFloat(self.sitio.longitud)
        }

        self.lat_lng = parseFloat(self.sitio.latitud)+','+parseFloat(self.sitio.longitud)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : ''
        console.log(error)
      })
    }
  },

  methods: {
    actualizar: function() {
      var self = this

      var sitio = Object.assign({}, self.sitio)
      self.bandera_spinner = true

      if (sitio.nombre == null || sitio.nombre == '') {
        iu.msg.warning('Es necesario ingresar un nombre al sitio')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return
      }

      SitioSrv.actualizar(sitio).then(response => {
        iu.msg.success('Sitio actualizado correctamente')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar el sitio'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },
    atras: function() {
      this.$router.go(-1)
    },
    cerrar: function() {
      this.atras()
    },

    guardar: function() {
      var self = this

      var sitio = Object.assign({}, self.sitio)
      self.bandera_spinner = true

      if (sitio.nombre == null || sitio.nombre == '') {
        iu.msg.warning('Es necesario ingresar un nombre al sitio')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return
      }

      SitioSrv.guardar(sitio).then(response => {
        iu.msg.success('El sitio se guardó correctamente')
        self.limpiar()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar el sitio'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },
    limpiar() {
      var self = this
      self.posicionInicial = {
        lat:0,
        lng:0
      }
      self.lat_lng = '0,0'
      self.mapaZoom = 12,

      Object.assign(self.sitio, self.sitioInicial)
    },
    mostrarPosicion: function(event) {
      var self = this 

      self.sitio.latitud = event.latLng.lat()
      self.sitio.longitud = event.latLng.lng()

      self.posicionInicial = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }

      self.lat_lng = event.latLng.lat()+','+event.latLng.lng()
    },

    actualizarPosicion: function() {
      var self = this, array = self.lat_lng.split(',')

      if(array.length == 1 || array.length > 2) {
        iu.msg.warning("No es una coordenada válida, ingresé una coordenada correcta.")
        self.lat_lng = parseFloat(self.sitio.latitud)+','+parseFloat(self.sitio.longitud)
        return
      }
      let latitud = parseFloat(array[0]), longitud = parseFloat(array[1])

      if(!latitud || !longitud) {
        iu.msg.warning("Latitud o longitud no son coordenadas válidas; ingresé una coordenada correcta.")
        self.lat_lng = parseFloat(self.sitio.latitud)+','+parseFloat(self.sitio.longitud)
        return
      }

      self.sitio.latitud = latitud
      self.sitio.longitud = longitud

      self.posicionInicial = {
        lat: latitud,
        lng: longitud
      }

      self.lat_lng = latitud+','+longitud
    },

  }
}
</script>

<style scoped>

</style>